const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type){
    default:{
      return initialState;
    }
  }
}

export default reducer